.example-enter {
  height: 0px;
}

.example-enter.example-enter-active {
  height: 100px;
  -webkit-transition: height .3s ease;
}

.example-leave.example-leave-active {
  height: 0px;
  -webkit-transition: height .3s ease;
}